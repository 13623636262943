export default {
  difficulty: {
    easy: 'EASY',
    medium: 'MEDIUM',
    hard: 'HARD'
  },
  tabs: {
    recent: 'Recent',
    bests: 'Bests',
    my_games: 'My Bets'
  },
  common: {
    how2play: 'How to Play ?',
    min: 'min',
    max: 'max',
    play: 'PLAY',
    take: 'TAKE',
    player: 'Player',
    bet: 'Bet',
    income: 'Income',
    step: 'Step',
    game_title: 'Tower Game',
    verify_ticket: 'Verify Ticket',
    success: 'Success',
    uwon: 'You Win !',
    player_info: 'Player Info',
    statistics: 'Statistics',
    close: 'Close'
  }
}
